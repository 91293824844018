var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calls-single-list"},[_c('div',{staticClass:"d-flex justify-content-end mb-3"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){_vm.initiateCallModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('order-confirmation.calls.single.initiate-call'))+" ")])]),_c('div',{staticClass:"calls-single-table"},[_c('data-table',{ref:"callsTables",attrs:{"data":_vm.calls,"fields":_vm.translatedTableFields,"pagination":_vm.pagination,"loading":_vm.callsLoading},on:{"changePage":function($event){return _vm.onPageChange($event)}},scopedSlots:_vm._u([{key:"createdAt",fn:function({ row }){return [_vm._v(" "+_vm._s(row.createdAt)+" ")]}},{key:"status",fn:function({ row }){return [_c('span',{class:_vm.getStatusClass(row.status)},[_vm._v(" "+_vm._s(row.status)+" ")])]}},{key:"actions",fn:function({ row }){return [_c('button',{staticClass:"btn px-3 btn-sm btn-primary",on:{"click":function($event){return _vm.toggleDetails(row.id)}}},[_c('i',{class:_vm.$refs.callsTables.activeRowDetailsId === row.id
                ? 'fa fa-eye-slash'
                : 'fa fa-eye'})])]}},{key:"details",fn:function({ row }){return [_c('data-table',{attrs:{"data":row.cdrs,"fields":_vm.translatedCDRsTableFields},scopedSlots:_vm._u([{key:"callDate",fn:function({ row }){return [_vm._v(" "+_vm._s(row.callDate)+" ")]}},{key:"orderStatus",fn:function({ row }){return [_c('span',{class:_vm.getStatusClass(row.orderStatus)},[_vm._v(" "+_vm._s(row.orderStatus)+" ")])]}},{key:"callDuration",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(row.duration > 0 ? row.duration : '')+" ")])]}}],null,true)})]}}])})],1),_c('modal',{attrs:{"isOpen":_vm.initiateCallModalOpen},on:{"dismiss":function($event){_vm.initiateCallModalOpen = false}}},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"head mb-4"},[_c('h1',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t('order-confirmation.calls.single.initiate-call-modal.title'))+" ")])]),_c('div',{staticClass:"body d-flex flex-column align-items-center"},[_c('b-form-select',{staticClass:"border mb-2",attrs:{"options":_vm.callScenarios},on:{"change":_vm.onSelectedCallScenarioChange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"disabled":"","value":""}},[_vm._v(" "+_vm._s(_vm.$t( 'order-confirmation.calls.single.initiate-call-modal.form.placeholders.flow' ))+" ")])]},proxy:true}]),model:{value:(_vm.selectedScenario),callback:function ($$v) {_vm.selectedScenario=$$v},expression:"selectedScenario"}}),_c('field',{staticClass:"mb-2 w-100",attrs:{"placeholder":_vm.$t(
              'order-confirmation.calls.single.initiate-call-modal.form.placeholders.phone'
            ),"error":_vm.initiateCallErrors.phone},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}}),_c('b-form-input',{staticClass:"border mb-2",attrs:{"placeholder":_vm.$t(
              'order-confirmation.calls.single.initiate-call-modal.form.placeholders.order-number'
            )},model:{value:(_vm.model.orderNumber),callback:function ($$v) {_vm.$set(_vm.model, "orderNumber", $$v)},expression:"model.orderNumber"}}),_c('div',{staticClass:"params-container w-100"},[(_vm.paramsLoading)?_c('half-circle-spinner',{staticClass:"m-auto",attrs:{"animation-duration":1500,"size":25,"color":"#74b9ff"}}):_vm._e(),(!_vm.paramsLoading && !!_vm.params.length)?_c('div',{staticClass:"params"},[_c('h5',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(_vm.$t( 'order-confirmation.calls.single.initiate-call-modal.form.params' ))+" ")]),_vm._l((_vm.params),function(param,idx){return _c('div',{key:param.variable_name,staticClass:"param mb-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(param.variable_name)+" ("+_vm._s(param.type)+") ")]),(param.type === 'money')?_c('div',{staticClass:"money-inputs"},[_c('div',{staticClass:"flex-fill d-flex"},[_c('b-form-input',{staticClass:"mx-2 border pl-2",attrs:{"type":"number","placeholder":_vm.$t(
                          'order-confirmation.calls.single.initiate-call-modal.form.placeholders.amount'
                        ),"value":_vm.model.variables[idx].value.amount},on:{"change":function($event){_vm.model.variables[idx].value.amount = $event}}}),_c('b-form-select',{staticClass:"border",attrs:{"options":_vm.currencies},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":undefined,"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t( 'order-confirmation.calls.single.initiate-call-modal.form.placeholders.currency' ))+" ")])]},proxy:true}],null,true),model:{value:(_vm.model.variables[idx].value.currency),callback:function ($$v) {_vm.$set(_vm.model.variables[idx].value, "currency", $$v)},expression:"model.variables[idx].value.currency"}})],1)]):_vm._e(),(param.type === 'full-number')?_c('div',{staticClass:"full-number-inputs"},[_c('div',{staticClass:"flex-fill d-flex"},[_c('b-form-input',{staticClass:"mx-2 border pl-2",attrs:{"placeholder":_vm.$t(
                          'order-confirmation.calls.single.initiate-call-modal.form.placeholders.value'
                        )},on:{"keypress":_vm.numbersOnly},model:{value:(_vm.model.variables[idx].value),callback:function ($$v) {_vm.$set(_vm.model.variables[idx], "value", $$v)},expression:"model.variables[idx].value"}})],1)]):_vm._e(),(param.type === 'separated-number')?_c('div',{staticClass:"separated-number-inputs"},[_c('div',{staticClass:"flex-fill d-flex"},[_c('b-form-input',{staticClass:"mx-2 border pl-2",attrs:{"placeholder":_vm.$t(
                          'order-confirmation.calls.single.initiate-call-modal.form.placeholders.value'
                        )},on:{"keypress":_vm.numbersOnly},model:{value:(_vm.model.variables[idx].value),callback:function ($$v) {_vm.$set(_vm.model.variables[idx], "value", $$v)},expression:"model.variables[idx].value"}})],1)]):_vm._e(),(param.type === 'text')?_c('div',{staticClass:"text-inputs"},[_c('div',{staticClass:"flex-fill d-flex"},[_c('b-form-textarea',{staticClass:"mx-2 border pl-2",attrs:{"placeholder":_vm.$t(
                          'order-confirmation.calls.single.initiate-call-modal.form.placeholders.value'
                        ),"rows":"3","max-rows":"6"},model:{value:(_vm.model.variables[idx].value),callback:function ($$v) {_vm.$set(_vm.model.variables[idx], "value", $$v)},expression:"model.variables[idx].value"}})],1)]):_vm._e()])])})],2):_vm._e()],1),(_vm.selectedScenario)?_c('div',[_c('b-form-checkbox',{attrs:{"size":"lg"},model:{value:(_vm.useDefaultFlowOptions),callback:function ($$v) {_vm.useDefaultFlowOptions=$$v},expression:"useDefaultFlowOptions"}},[_vm._v(" "+_vm._s(_vm.$t( 'order-confirmation.calls.single.initiate-call-modal.use-default-flow-options' ))+" ")]),(!_vm.useDefaultFlowOptions)?_c('div',{staticClass:"custom-options"},[_c('div',{staticClass:"row w-100 mb-4"},[_c('div',{staticClass:"col-12"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('order-confirmation.create-flow.form.trials-count.label')))]),_c('b-form-input',{staticClass:"border",class:{ 'is-invalid': _vm.initiateCallErrors.trialsCount },attrs:{"type":"number","min":"0","placeholder":_vm.$t(
                      'order-confirmation.create-flow.form.trials-count.placeholder'
                    )},model:{value:(_vm.model.trialsCount),callback:function ($$v) {_vm.$set(_vm.model, "trialsCount", $$v)},expression:"model.trialsCount"}}),(_vm.initiateCallErrors.trialsCount)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.initiateCallErrors.trialsCount)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t( 'order-confirmation.create-flow.form.minutes-to-wait-before-first-trial.label' )))]),_c('b-form-input',{staticClass:"border",class:{
                    'is-invalid':
                      _vm.initiateCallErrors.minutesToWaitBeforeFirstTrial
                  },attrs:{"type":"number","min":"0","placeholder":_vm.$t(
                      'order-confirmation.create-flow.form.minutes-to-wait-before-first-trial.placeholder'
                    )},model:{value:(_vm.model.minutesToWaitBeforeFirstTrial),callback:function ($$v) {_vm.$set(_vm.model, "minutesToWaitBeforeFirstTrial", $$v)},expression:"model.minutesToWaitBeforeFirstTrial"}}),(_vm.initiateCallErrors.minutesToWaitBeforeFirstTrial)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.initiateCallErrors.minutesToWaitBeforeFirstTrial)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t( 'order-confirmation.create-flow.form.delay-minutes-between-trials.label' )))]),_c('b-form-input',{staticClass:"border",class:{
                    'is-invalid': _vm.initiateCallErrors.delayMinutesBetweenTrials
                  },attrs:{"type":"number","min":"0","placeholder":_vm.$t(
                      'order-confirmation.create-flow.form.delay-minutes-between-trials.placeholder'
                    )},model:{value:(_vm.model.delayMinutesBetweenTrials),callback:function ($$v) {_vm.$set(_vm.model, "delayMinutesBetweenTrials", $$v)},expression:"model.delayMinutesBetweenTrials"}}),(_vm.initiateCallErrors.delayMinutesBetweenTrials)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.initiateCallErrors.delayMinutesBetweenTrials))]):_vm._e()],1)])]):_vm._e()],1):_vm._e(),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.isWaitingResponse},on:{"click":_vm.onInitiateCall}},[_vm._v(" "+_vm._s(_vm.$t( 'order-confirmation.calls.single.initiate-call-modal.form.initiate-call' ))+" ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }