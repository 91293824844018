<template>
  <div class="calls-single-list">
    <div class="d-flex justify-content-end mb-3">
      <button class="btn btn-primary" @click="initiateCallModalOpen = true">
        {{ $t('order-confirmation.calls.single.initiate-call') }}
      </button>
    </div>
    <div class="calls-single-table">
      <data-table
        ref="callsTables"
        :data="calls"
        :fields="translatedTableFields"
        :pagination="pagination"
        :loading="callsLoading"
        @changePage="onPageChange($event)"
      >
        <template v-slot:createdAt="{ row }">
          {{ row.createdAt }}
        </template>

        <template v-slot:status="{ row }">
          <span :class="getStatusClass(row.status)">
            {{ row.status }}
          </span>
        </template>

        <template v-slot:actions="{ row }">
          <button
            class="btn px-3 btn-sm btn-primary"
            @click="toggleDetails(row.id)"
          >
            <i
              :class="
                $refs.callsTables.activeRowDetailsId === row.id
                  ? 'fa fa-eye-slash'
                  : 'fa fa-eye'
              "
            ></i>
          </button>
        </template>
        <template v-slot:details="{ row }">
          <data-table :data="row.cdrs" :fields="translatedCDRsTableFields">
            <template v-slot:callDate="{ row }">
              {{ row.callDate }}
            </template>

            <template v-slot:orderStatus="{ row }">
              <span :class="getStatusClass(row.orderStatus)">
                {{ row.orderStatus }}
              </span>
            </template>
            <template v-slot:callDuration="{ row }">
              <span>
                {{ row.duration > 0 ? row.duration : '' }}
              </span>
            </template>
          </data-table>
        </template>
      </data-table>
    </div>

    <modal
      :isOpen="initiateCallModalOpen"
      @dismiss="initiateCallModalOpen = false"
    >
      <div class="d-flex flex-column align-items-center">
        <div class="head mb-4">
          <h1 class="m-0">
            {{
              $t('order-confirmation.calls.single.initiate-call-modal.title')
            }}
          </h1>
        </div>
        <div class="body d-flex flex-column align-items-center">
          <b-form-select
            class="border mb-2"
            v-model="selectedScenario"
            :options="callScenarios"
            @change="onSelectedCallScenarioChange"
          >
            <template v-slot:first>
              <b-form-select-option disabled value="">
                {{
                  $t(
                    'order-confirmation.calls.single.initiate-call-modal.form.placeholders.flow'
                  )
                }}
              </b-form-select-option>
            </template>
          </b-form-select>
          <field
            class="mb-2 w-100"
            v-model="model.phone"
            :placeholder="
              $t(
                'order-confirmation.calls.single.initiate-call-modal.form.placeholders.phone'
              )
            "
            :error="initiateCallErrors.phone"
          >
          </field>

          <b-form-input
            class="border mb-2"
            :placeholder="
              $t(
                'order-confirmation.calls.single.initiate-call-modal.form.placeholders.order-number'
              )
            "
            v-model="model.orderNumber"
          ></b-form-input>

          <div class="params-container w-100">
            <half-circle-spinner
              :animation-duration="1500"
              :size="25"
              color="#74b9ff"
              class="m-auto"
              v-if="paramsLoading"
            />
            <div class="params" v-if="!paramsLoading && !!params.length">
              <h5 class="mt-0">
                {{
                  $t(
                    'order-confirmation.calls.single.initiate-call-modal.form.params'
                  )
                }}
              </h5>
              <div
                v-for="(param, idx) in params"
                class="param mb-2"
                :key="param.variable_name"
              >
                <div class="d-flex align-items-center">
                  <p class="m-0">
                    {{ param.variable_name }} ({{ param.type }})
                  </p>
                  <div class="money-inputs" v-if="param.type === 'money'">
                    <div class="flex-fill d-flex">
                      <b-form-input
                        class="mx-2 border pl-2"
                        type="number"
                        :placeholder="
                          $t(
                            'order-confirmation.calls.single.initiate-call-modal.form.placeholders.amount'
                          )
                        "
                        :value="model.variables[idx].value.amount"
                        @change="model.variables[idx].value.amount = $event"
                      ></b-form-input>
                      <b-form-select
                        v-model="model.variables[idx].value.currency"
                        class="border"
                        :options="currencies"
                      >
                        <template #first>
                          <b-form-select-option :value="undefined" disabled>
                            {{
                              $t(
                                'order-confirmation.calls.single.initiate-call-modal.form.placeholders.currency'
                              )
                            }}
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div
                    class="full-number-inputs"
                    v-if="param.type === 'full-number'"
                  >
                    <div class="flex-fill d-flex">
                      <b-form-input
                        class="mx-2 border pl-2"
                        @keypress="numbersOnly"
                        :placeholder="
                          $t(
                            'order-confirmation.calls.single.initiate-call-modal.form.placeholders.value'
                          )
                        "
                        v-model="model.variables[idx].value"
                      ></b-form-input>
                    </div>
                  </div>
                  <div
                    class="separated-number-inputs"
                    v-if="param.type === 'separated-number'"
                  >
                    <div class="flex-fill d-flex">
                      <b-form-input
                        class="mx-2 border pl-2"
                        @keypress="numbersOnly"
                        :placeholder="
                          $t(
                            'order-confirmation.calls.single.initiate-call-modal.form.placeholders.value'
                          )
                        "
                        v-model="model.variables[idx].value"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="text-inputs" v-if="param.type === 'text'">
                    <div class="flex-fill d-flex">
                      <b-form-textarea
                        class="mx-2 border pl-2"
                        :placeholder="
                          $t(
                            'order-confirmation.calls.single.initiate-call-modal.form.placeholders.value'
                          )
                        "
                        v-model="model.variables[idx].value"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedScenario">
            <b-form-checkbox size="lg" v-model="useDefaultFlowOptions">
              {{
                $t(
                  'order-confirmation.calls.single.initiate-call-modal.use-default-flow-options'
                )
              }}
            </b-form-checkbox>
            <div class="custom-options" v-if="!useDefaultFlowOptions">
              <div class="row w-100 mb-4">
                <div class="col-12">
                  <label for="">{{
                    $t('order-confirmation.create-flow.form.trials-count.label')
                  }}</label>
                  <b-form-input
                    type="number"
                    class="border"
                    min="0"
                    :placeholder="
                      $t(
                        'order-confirmation.create-flow.form.trials-count.placeholder'
                      )
                    "
                    v-model="model.trialsCount"
                    :class="{ 'is-invalid': initiateCallErrors.trialsCount }"
                  ></b-form-input>

                  <span
                    v-if="initiateCallErrors.trialsCount"
                    class="text-danger"
                  >
                    {{ initiateCallErrors.trialsCount }}
                  </span>
                </div>
                <div class="col-12">
                  <label for="">{{
                    $t(
                      'order-confirmation.create-flow.form.minutes-to-wait-before-first-trial.label'
                    )
                  }}</label>
                  <b-form-input
                    type="number"
                    class="border"
                    min="0"
                    :placeholder="
                      $t(
                        'order-confirmation.create-flow.form.minutes-to-wait-before-first-trial.placeholder'
                      )
                    "
                    v-model="model.minutesToWaitBeforeFirstTrial"
                    :class="{
                      'is-invalid':
                        initiateCallErrors.minutesToWaitBeforeFirstTrial
                    }"
                  ></b-form-input>
                  <span
                    v-if="initiateCallErrors.minutesToWaitBeforeFirstTrial"
                    class="text-danger"
                  >
                    {{ initiateCallErrors.minutesToWaitBeforeFirstTrial }}
                  </span>
                </div>
                <div class="col-12">
                  <label for="">{{
                    $t(
                      'order-confirmation.create-flow.form.delay-minutes-between-trials.label'
                    )
                  }}</label>
                  <b-form-input
                    type="number"
                    class="border"
                    min="0"
                    :placeholder="
                      $t(
                        'order-confirmation.create-flow.form.delay-minutes-between-trials.placeholder'
                      )
                    "
                    v-model="model.delayMinutesBetweenTrials"
                    :class="{
                      'is-invalid': initiateCallErrors.delayMinutesBetweenTrials
                    }"
                  ></b-form-input>
                  <span
                    v-if="initiateCallErrors.delayMinutesBetweenTrials"
                    class="text-danger"
                    >{{ initiateCallErrors.delayMinutesBetweenTrials }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <button
            class="btn btn-primary"
            @click="onInitiateCall"
            :disabled="isWaitingResponse"
          >
            {{
              $t(
                'order-confirmation.calls.single.initiate-call-modal.form.initiate-call'
              )
            }}
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import DataTable from '../../elements/Table.vue'
import Modal from '../../elements/Modal.vue'
import orderConfirmationService from '../../services/orderConfirmation.service'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import moment from 'moment'
import { currencies } from '../../data/Currencies'
import { object, string, number } from 'yup'
import { yupToKV } from '../../utils/yup'
import Field from '../../elements/Field.vue'
import { isValidPhoneNumber } from '../../utils/phone'

export default {
  data() {
    return {
      fields: [
        {
          accessor: 'phone',
          header: 'order-confirmation.calls.single.table.phone'
        },
        {
          accessor: 'createdAt',
          header: 'order-confirmation.calls.single.table.created-at'
        },
        {
          accessor: 'orderNumber',
          header: 'order-confirmation.calls.single.table.order-number'
        },
        {
          accessor: 'status',
          header: 'order-confirmation.calls.single.table.status'
        },
        {
          accessor: 'actions',
          header: 'order-confirmation.calls.single.table.actions'
        }
      ],
      CDRsFields: [
        {
          accessor: 'trial',
          header: 'order-confirmation.calls.single.table.trial-num'
        },
        {
          accessor: 'callDate',
          header: 'order-confirmation.calls.single.table.call-date'
        },
        {
          accessor: 'orderStatus',
          header: 'order-confirmation.calls.single.table.order-status'
        },
        {
          accessor: 'callStatus',
          header: 'order-confirmation.calls.single.table.call-status'
        },
        {
          accessor: 'callDuration',
          header: 'order-confirmation.calls.single.table.call-duration'
        }
      ],
      currencies: currencies,
      calls: [],
      callsLoading: false,
      pagination: {
        totalPages: 0,
        page: 1
      },
      initiateCallModalOpen: false,
      callScenarios: [],
      selectedScenario: '',
      params: [],
      paramsLoading: false,
      model: {
        phone: '',
        variables: [],
        trialsCount: '0',
        minutesToWaitBeforeFirstTrial: '0',
        delayMinutesBetweenTrials: '0'
      },
      initiateCallErrors: {},
      isWaitingResponse: false,
      selectedCallDetails: null,
      files: [],
      useDefaultFlowOptions: true
    }
  },
  components: { DataTable, Modal, HalfCircleSpinner, Field },
  mounted() {
    this.getCalls()
    this.getCallScenarios()
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page
      this.getCalls(page)
    },
    async getCalls() {
      this.callsLoading = true
      const res = await orderConfirmationService.getFormCallsDetails(
        this.pagination.page
      )
      const { callRequests, ...pagination } = res
      this.calls = callRequests
      this.pagination.totalPages = pagination.totalPages
      this.callsLoading = false
    },
    toggleDetails(id) {
      this.$refs.callsTables.toggleDetails(id)
    },
    async getCallScenarios() {
      const res = await orderConfirmationService.getCallScenarios(1, 999)
      this.callScenarios = res.orderScenarios.map(cs => ({
        value: cs.id,
        text: cs.name
      }))
    },
    removeDuplicateParams(arr) {
      const uniqueArray = arr.filter((value, index) => {
        return (
          index ===
          arr.findIndex(obj => {
            return obj.variable_name === value.variable_name
          })
        )
      })

      return uniqueArray
    },
    async onSelectedCallScenarioChange(callScenarioId) {
      this.paramsLoading = true
      const result = await orderConfirmationService.getCallScenarioParams(
        callScenarioId
      )

      // remove duplicates
      const ignoredParams = ['orderNumber']

      const uniqueParams = this.removeDuplicateParams(result)
      this.params = uniqueParams.filter(
        param => !ignoredParams.includes(param.variable_name)
      )

      const defaultValues = {
        money: {},
        text: '',
        'separated-number': '',
        'full-number': ''
      }

      this.model.variables = uniqueParams
        .filter(param => !ignoredParams.includes(param.variable_name))
        .map(param => ({
          variable_name: param.variable_name,
          value: JSON.parse(JSON.stringify(defaultValues[param.type]))
        }))

      this.paramsLoading = false
    },
    resetForm() {
      this.model = {
        phone: '',
        variables: [],
        trialsCount: '0',
        minutesToWaitBeforeFirstTrial: '0',
        delayMinutesBetweenTrials: '0'
      }
      this.params = []
      this.initiateCallErrors = {}
      this.selectedScenario = ''
      this.useDefaultFlowOptions = true
    },
    async onInitiateCall() {
      if (!this.selectedScenario) {
        this.toast(
          this.$t(
            'order-confirmation.calls.single.initiate-call-modal.errors.required.selected-flow'
          ),
          {
            type: 'error'
          }
        )
        return
      }

      let schema = this.initiateCallSchema

      if (!this.useDefaultFlowOptions) {
        schema = object().shape({
          phone: string()
            .trim()
            .test(
              'isValidPhone',
              this.$t(
                'order-confirmation.calls.single.initiate-call-modal.errors.invalid.phone'
              ),
              isValidPhoneNumber
            )
            .required(
              this.$t(
                'order-confirmation.calls.single.initiate-call-modal.errors.required.phone'
              )
            ),
          trialsCount: number().min(
            1,
            this.$t(
              'order-confirmation.create-flow.errors.length.trials-count',
              {
                num: 1
              }
            )
          ),
          minutesToWaitBeforeFirstTrial: number().min(
            0,
            this.$t(
              'order-confirmation.create-flow.errors.length.minutes-to-wait-before-first-trial',
              { num: 0 }
            )
          ),
          delayMinutesBetweenTrials: number().min(
            5,
            this.$t(
              'order-confirmation.create-flow.errors.length.delay-minutes-between-trials',
              { num: 5 }
            )
          )
        })
      }

      await schema.validate(this.model, { abortEarly: false }).catch(err => {
        this.initiateCallErrors = yupToKV(err)
      })

      if (!schema.isValidSync(this.model)) {
        return
      }

      this.initiateCallErrors = {}
      this.isWaitingResponse = true
      try {
        this.model.phone = this.model.phone.replace(/\D/g, '')
        await orderConfirmationService.initiateCall(this.selectedScenario, {
          ...this.model,
          phone: this.model.phone.replace(/\D/g, ''),
          useDefaultFlowOptions: this.useDefaultFlowOptions,
          trialsCount: +this.model.trialsCount,
          minutesToWaitBeforeFirstTrial: +this.model
            .minutesToWaitBeforeFirstTrial,
          delayMinutesBetweenTrials: +this.model.delayMinutesBetweenTrials
        })
        this.toast(
          this.$t(
            'order-confirmation.calls.single.initiate-call-modal.toasts.call-initiated-successfully'
          )
        )
        this.initiateCallModalOpen = false
        this.getCalls()
        this.resetForm()
      } catch (error) {
        console.log(error)
        this.isWaitingResponse = false

        this.toast(error.response.data.message, {
          type: 'error'
        })
      } finally {
        this.isWaitingResponse = false
      }
    },
    getStatusClass(status) {
      return status
        .toLowerCase()
        .trim()
        .split(' ')
        .join('-')
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY hh:mm A')
    },
    numbersOnly(evt) {
      evt = evt ? evt : window.event
      if (!/[0-9.]/.test(evt.key)) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  },
  computed: {
    initiateCallSchema() {
      return object().shape({
        phone: string()
          .test(
            'isValidPhone',
            this.$t(
              'order-confirmation.calls.single.initiate-call-modal.errors.invalid.phone'
            ),
            isValidPhoneNumber
          )
          .required(
            this.$t(
              'order-confirmation.calls.single.initiate-call-modal.errors.required.phone'
            )
          )
      })
    },
    translatedTableFields() {
      return this.fields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    },
    translatedCDRsTableFields() {
      return this.CDRsFields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.params-group {
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 14px;
}

.order-confirmed {
  color: green;
  /* Define your colors here */
}

.not-yet-initiated {
  color: orange;
}

.waiting-customer-action {
  color: blue;
}

.call-failed {
  color: red;
}

.order-cancelled {
  color: darkred;
}

.no-valid-input-provided {
  color: brown;
}
</style>
